body {
 background-color: wheat;
}
.container {
    
    margin: 1rem 2rem;
  }
.head-one{
  background-color: lightblue;
  box-shadow: 2px 2px 5px 2px rgba(.3, .3, .3, .3);
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: .5px;
  font-family: Garamond, "Baskerville Old Face", "Hoefler Text", "Times New Roman", serif;
}
.card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: .25rem;
    box-shadow: 0 0 5px 2px black(0,0,0,.3);
    background-color: white;
    transform-style: preserve-3d;
    transition: 150ms;
    cursor: pointer;
    transform: perspective(1000px) rotateY(var(--rotate-y, 0));
}

.card :hover{
    --translate-y: -2px;
    box-shadow: 0 0 5px 2px black(0,0,0,.5);
}

.card.flip{
    --rotate-y: 180deg;
}

.card .front{
    left: 0;
}

.card .front, 
.card .back {
    position:absolute;
    padding: 1rem;
    backface-visibility: hidden;
}

.card .back {
    transform: rotateY(180deg);
}

.card-grid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: white;
    padding: 1 1.5rem;
    box-shadow: 2px 2px 5px 2px rgba(.3, .3, .3, .3);
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin: .5rem;
  }
  
  .form-group > label {
    color: #777;
    font-size: .75rem;
    margin-bottom: .25rem;
  }
  
  .btn {
    background-color: hsl(200, 100%, 50%);
    color: white;
    padding: .5em 1em;
    border-radius: .3em;
    border: none;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: hsl(200, 100%, 40%);
  }
  
